/* eslint-disable max-len */

import React, { useState }from "react";
import { Container } from "semantic-ui-react";
import { useRoutes } from 'hookrouter';
import Nav from './Nav';
import Home from "./Home";
import About from "./About";
import NotFound from "./NotFound";

const routes = {
  '/': () => <Home />,
  '/About': () => <About />
};

const App = () => {
  const routeResult = useRoutes(routes);
  const [activeItem, setActiveItem] = useState(document.location.pathname);

  return (
    <Container>
      <Nav activeItem={activeItem} setActiveItem={setActiveItem} />
      <Container style={{ padding: "1em 2em" }}>
        {routeResult || <NotFound setActiveItem={setActiveItem} />}
      </Container>
    </Container>
  );
}

export default App;
