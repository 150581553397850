import React from "react";
import { Header, Image, Grid } from "semantic-ui-react";

const Wine = ({ name, description, vintage, imageSrc }) => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Image
              src={require(`${imageSrc}`)}
              size="medium"
              rounded
              floated="left"
            />
          </Grid.Column>
          <Grid.Column width={11}>
            <Header as="h1">
              {name} - {vintage}
            </Header>
            <div>{description}</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Wine;
