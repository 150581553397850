import React from "react";
import { Grid, Image } from "semantic-ui-react";

const initialPlanting = './semantic-ui/site/assets/images/vineyard/vineyard.jpeg'

const Wine = () => {
  return (
    <>
      <Grid classname="ui equal width center aligned grid container">
        <Grid.Row>
          <Image
            src={require(`${initialPlanting}`)}
            size='large'
            rounded
            floated='left'
            bordered
          />
          <p>
            Be not offended when your ex drinks wine. Trichloroanisole in the
            cork can impart musty, mouldy overtones. Such a wine is called
            "corked." Amaron is made primarily from Corvina grapes dried on
            racks before pressing. Cork it! Chenin blanc is a white wine grape
            grown in the Loire Valley of France. Fruity flavors, with aromas of
            rose petals, peaches, and allspice. Wine, women, and song – not
            necessarily in that order. Sangiovese – go ahead, pronounce it. Red
            wines usually trump whites in alcohol content. The aftertaste, or
            "finish," is the primary factor in judging the quality and character
            of wine. Aroma, bouquet, nose – wine is smelly business.
            Trichloroanisole, or stinky cork, is produced when chlorine used in
            sanitization comes into contact with natural cork-dwelling bacteria.
            The proper way to hold a wineglass is to position it so that the
            wine goes into your mouth. Dry Lambrusco pairs well with junk food.
            Some wines are made from rotting grapes: Botrytis, or noble rot,
            makes good dessert wine. Loamy soils, which are usually preferred
            for growing most plants, actually produce rather flavorless wines.
            Chenin blanc boasts a bouquet of fresh pears, saffron, jasmine,
            ginger, and quince. Acidity is a key element in a wine's longevity.
            Chardonnay's buttery, toasty or clove-like finish. Burgundy is the
            definition of a strong red. A grape in the glass is worth two on the
            vine. Red wines are well attributed to positive health benefits. How
            do you hold a wine glass? There is a right and a wrong way. Don't be
            shy: wine experts stick their nose deep into the glass in order to
            pick up the complex nuances. Ideal for sipping, Gewürztraminer is
            one of the sweeter whites. A lingering finish indicates a luscious
            vintage!
          </p>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Wine;
