import React from "react";
import { Menu } from "semantic-ui-react";
import { A } from 'hookrouter';

const Nav = ({activeItem, setActiveItem}) => {
  return (
    <>
      <Menu
        pointing 
        secondary
        size='massive'
      >
        <Menu.Item header>Terroir Noir</Menu.Item>
        <Menu.Item
          name="home"
          as={A}
          href="/"
          active={activeItem === "/"}
          onClick={() => setActiveItem("/")}
        />
        <Menu.Item
          name="about"
          as={A}
          href="/About"
          active={activeItem === "/About"}
          onClick={() => setActiveItem("/About")}
        />
      </Menu>
    </>
  );
}

export default Nav;
