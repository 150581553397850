import React from "react";
import { Grid } from "semantic-ui-react";

const Wine = () => {
  return (
    <>
      <Grid classname="ui equal width center aligned grid container">
        <Grid.Row>
          <p>
            Pinot Grigio and Pinot Gris are two names for the same wine. Monks
            and monasteries of the Roman Catholic Church have had an important
            influence on the history of Burgundy wine. Wine, women, and song –
            not necessarily in that order. In order to increase the alcohol
            content of their wines, some vintners began adding sugar to the
            grape must in a process called chaptalization. In California, the
            main stylistic difference in Cabernet Sauvignon is between hillside
            / mountain vineyards and those on flatter terrain like valley
            floors. Smoky is usually a byproduct of oak barrels, or, less often,
            of drunken arson. Starts strong on the tongue with explosions of
            plum, floral, fruits. Bacchus – the Roman god of wine, or a white
            grape from Germany. High-end corks are handmade. When at a wine
            tasting event, one is not frowned upon for spitting wine out. It may
            be the oldest known type of wine, as it is the most straightforward
            to make with the skin contact method. Some wines are made from
            rotting grapes: Botrytis, or noble rot, makes good dessert wine.
            Gulp. Spice, strawberries, oak and tar. Can't taste them? Drink
            more. Pinot noir is French for "black pinecone," a description of
            the grape's appearance. Cork it! The Romans cooked grape juice in
            lead pots to sweeten their wine... and to poison their wine.
            Unsubstantiated rumor states that the more colorful the label, the
            less quality the wine. Trichloroanisole in the cork can impart
            musty, mouldy overtones. Such a wine is called "corked." The best
            wines impart a lingering, complex and rich finish. A botrytized wine
            is made using grapes that have been allowed to become mouldy.
          </p>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Wine;
