import React from "react";
import {
  Divider,
  Grid,
  Header,
  List
} from "semantic-ui-react";

const About = () => {
  return (
    <>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column>
            <Divider horizontal>
              <Header as="h1">Visit</Header>
            </Divider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <p>
              To schedule an appointment to tour the vineyard, please call or email a few days in advance.
              We will do our best to accommodate requests given plenty of notice, but at this time we live
              a few hours away.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <Divider horizontal>
              <Header as="h1">Contact</Header>
            </Divider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          {/* <Grid.Column width={4}>
            <List>
              <List.Item>
                <List.Icon
                  name="clock outline"
                  size="big"
                  verticalAlign="top"
                />
                <List.Content>
                  <List.Header>Hours</List.Header>
                  <List.Description>
                    <>
                      <div>Saturday: 11AM - 7 PM</div>
                      <div>Sunday: by appointment</div>
                    </>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column> */}
          <Grid.Column width={4}>
            <List>
              <List.Item>
                <List.Icon
                  name="phone"
                  size="big"
                  verticalAlign="top"
                  flipped="horizontally"
                />
                <List.Content>
                  <List.Header>Vineyard</List.Header>
                  <List.Description>
                    <a href="tel:+1-214-302-7397">+1 (214) 302-7397</a>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <List>
              <List.Item>
                <List.Icon name="mail outline" size="big" verticalAlign="top" />
                <List.Content>
                  <List.Header>Email</List.Header>
                  <List.Description>
                    <a href="mailto:terroirnoirwinery@gmail.com">
                      terroirnoirwinery@gmail.com
                    </a>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <List>
              <List.Item>
                <List.Icon name="map marker" size="big" verticalAlign="top" />
                <List.Content>
                  <List.Header>Terroir Noir</List.Header>
                  <List.Description>
                    <>
                      <div>9973 FM 1649</div>
                      <div>Gilmer, TX 75645</div>
                    </>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <Divider horizontal>
              <Header as="h1">Directions</Header>
            </Divider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered divided>
          <Grid.Column width={5}>
            <Header as="h3">From Dallas</Header>
            <List as="ol">
              <List.Item as="li">Travel East on I-20 for 90 miles</List.Item>
              <List.Item as="li">
                Exit 567 for TX-155 North for 11 miles
              </List.Item>
              <List.Item as="li">Right onto Broadway St. for 1 mile</List.Item>
              <List.Item as="li">Left onto TX-155 North for 15 miles</List.Item>
              <List.Item as="li">Right onto TX-154 East for 2 miles</List.Item>
              <List.Item as="li">Left on FM-555 for 3.5 miles</List.Item>
              <List.Item as="li">Continue on FM 1649 for 5 miles</List.Item>
              <List.Item as="li">Terroir Noir will be on the left.</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header as="h3">From Longview</Header>
            <List as="ol">
              <List.Item as="li">Travel North on US-259 for 15 miles</List.Item>
              <List.Item as="li">Left onto FM-726 for 0.5 miles</List.Item>
              <List.Item as="li">Right onto FM-1972 for 2.8 miles</List.Item>
              <List.Item as="li">Right on FM-1649 for 1.6 miles</List.Item>
              <List.Item as="li">Terroir Noir will be on the left.</List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={5}>
            <Header as="h3">From Shreveport</Header>
            <List as="ol">
              <List.Item as="li">Travel West on I-20 for 55 miles</List.Item>
              <List.Item as="li">Exit 599 for TX-281 Loop</List.Item>
              <List.Item as="li">
                Right onto TX-281 Loop for 6.8 miles
              </List.Item>
              <List.Item as="li">Exit right onto US-259</List.Item>
              <List.Item as="li">North on US-259 for 15 miles</List.Item>
              <List.Item as="li">Left onto FM-726 for 0.5 miles</List.Item>
              <List.Item as="li">Right onto FM-1972 for 2.8 miles</List.Item>
              <List.Item as="li">Right on FM-1649 for 1.6 miles</List.Item>
              <List.Item as="li">Terroir Noir will be on the left.</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Divider horizontal>
              <Header as="h1">Map</Header>
            </Divider>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <iframe
            title="Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2568.7497239862414!2d-94.79014214282063!3d32.75905677788325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1573613674674!5m2!1sen!2sus"
            width="600"
            height="450"
            frameborder="0"
            style={{ border: "1px solid black" }}
            allowfullscreen=""
          />
        </Grid.Row>
      </Grid>
    </>
  );
};

export default About;
