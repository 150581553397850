import React from "react";
import { Divider, Grid, Header, Image, Segment } from "semantic-ui-react";

const grapesSrc = "./semantic-ui/site/assets/images/vineyard/grapes.jpg";

const Home = () => {
  return (
    <>
      <Grid>
        <Grid.Row>
          <Segment>
            <Header as='h1' textAlign='center'>
              Terroir Noir Vineyard
            </Header>
            <Divider />
            <Image
              src={require(`${grapesSrc}`)}
              size="large"
              rounded
              floated="left"
              bordered
            />
            <p>
              Welcome to Terroir Noir Vineyard where our passion is to produce the highest quality
              grapes for making world class wines. Established in 2018, our vineyard consists of 40
              acres in the piney woods of East Texas.
            </p>
            <p>
              Starting with a small trial planting of Merlot, we plan to continue building out
              additional blocks of Merlot. Our initial goal is to produce ten to tweleve tons of
              Merlot and then start building out additional blocks of Dolcetto,
              Montepulciano, Syrah, Tempranillo, Vermentino, and Viognier.
            </p>
            <p>
              If you are interested in partnering with us on your winemaking journey, we would love
              to <a href="mailto:terroirnoirwinery@gmail.com">hear</a> from you.
            </p>
          </Segment>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Home;
