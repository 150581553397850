import React from "react";
import { Grid, Header, Image, Segment, Divider } from "semantic-ui-react";
import NotFoundImage from "./semantic-ui/site/assets/images/misc/NotFound.gif";

const NotFound = ({setActiveItem}) => {
  setActiveItem("NotFound");

  return (
    <>
      <Grid stackable centered>
        <Grid.Row>
          <Grid.Column width={16} >
          <Segment>
            <Header as='h1' textAlign='center'>
              Not Found
            </Header>
            <Divider />
            <Image src={NotFoundImage} large centered bordered rounded/>
          </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default NotFound;
