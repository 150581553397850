import React, { useState } from "react";
import { Grid, Menu } from "semantic-ui-react";
import Wine from "./Wine";

const Wines = () => {
  const wines = [
    {
      id: 1,
      name: "Merlot",
      vintage: 2019,
      group: "Red",
      description: "Deep dark ruby color with hints of dried plums and cherries and finishes with oaky notes that leave you wanting more. Pairs well with steak and lamb.",
      image: "./semantic-ui/site/assets/images/wines/Merlot.jpg"
    },
    {
      id: 2,
      name: "Syrah",
      vintage: 2019,
      group: "Red",
      description: "A dark purple color, full bodied wine with medium acidity. Flavors include currants, black cherry, and coffee, as it ages it will develop flavors of peppercorn. Pairs well with steak and lamb.",
      image: "./semantic-ui/site/assets/images/wines/Syrah.jpg"
    },
    {
      id: 3,
      name: "Tempranillo",
      vintage: 2019,
      group: "Red",
      description: "A deep garnent color, full bodied wine with medium acidity. Flavors include cherry, leather, and dark chocolate. Pairs well with steak and lamb.",
      image: "./semantic-ui/site/assets/images/wines/Tempranillo.jpg"
    },
    {
      id: 4,
      name: "Sangiovese",
      vintage: 2019,
      group: "Red",
      description: "A medium garnent color, medium bodied wine with high acidity and high tannins. An earthy wine with flavors that include blueberry, blackberry, plum, and violet. Aged in oak barrels that imparts a lovely vanilla spice to the wine. Serve at 65 degrees with grilled meats and vegetables.",
      image: "./semantic-ui/site/assets/images/wines/Sangiovese.jpg"
    },
    {
      id: 5,
      name: "Rosé de Merlot",
      vintage: 2019,
      group: "Rosé",
      description: "A light bodied, pale garnent wine that is best young. Full of fruity flavors that include raspberry, watermellon, and strawberry notes. Enjoy chilled around 50 degrees.",
      image: "./semantic-ui/site/assets/images/wines/rosedemerlot.jpg"
    },
    {
      id: 6,
      name: "Blanc du Bois",
      vintage: 2019,
      group: "White",
      description: "A pale golden wine with light body and can be consumed both young and aged. Fresh, crisp, and dry and includes flavors of grapefruit, peach, and pear. Aromas include orange blossom, tangerine, ginger, and honey.",
      image: "./semantic-ui/site/assets/images/wines/blancdubois.jpg"
    }
  ];

  const [activeItem, setActiveItem] = useState(wines[0].id);
  const activeWine = wines.find( ({ id }) => id === activeItem);

  const groups = wines
    .map(item => item.group)
    .filter((value, index, self) => self.indexOf(value) === index);

  const winesByGroup = (group) => {
    return wines
      .filter((value, index, self) => value.group === group);
  };

  const menu = groups
    .map(group => {
      return (
      <Menu.Item key={group}>
        <Menu.Header>{group}</Menu.Header>
        {
          winesByGroup(group)
            .map(wine => {
              return (
              <Menu.Menu>
              <Menu.Item
                key={wine.id}
                name={wine.name}
                active={activeItem === wine.id}
                onClick={() => setActiveItem(wine.id)}
                content={wine.name}
              />
              </Menu.Menu>
              );
            }
          )
      }
      </Menu.Item>
      );
    })

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <Menu vertical>
              {menu}
            </Menu>
           </Grid.Column>
           <Grid.Column stretched width={12}>
             <Wine name={activeWine.name} description={activeWine.description} vintage={activeWine.vintage} imageSrc={activeWine.image} />
           </Grid.Column>
         </Grid.Row>
       </Grid>
     </>
  );
};

export default Wines;
